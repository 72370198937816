<template>
  <el-container class="usermgr-index-main">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card class="user-search">
        <el-input v-model="searchKey" placeholder="请输入要搜索的 用户ID/登录账号/用户名">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-card>
      <el-card class="user-list">
        <std-table ref="module_table" :cols="userConfig.cols" :data="users" :pageindex.sync="pageIndex" :pagesize.sync="userConfig.pageSize"
          :dataTotal="users_total" disable-selection remotePaging @refresh="onRefresh" @selection-change="onSelectChange">
          <template #action="{row}">
            <el-link icon="el-icon-edit" @click="onEditUser(row.EB_USER_ID)"></el-link>
            <el-link icon="el-icon-delete" @click="onDeleteUser(row.EB_USER_ID)" style="margin-left:5px;"></el-link>
          </template>
          <template #state="{row}">
            <span v-text="StateObj[row.EB_STATE]"></span>
          </template>
        </std-table>
      </el-card>
    </el-main>
    <el-aside>
      <el-card class="user-mgr">
        <template #header>
          用户操作
        </template>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="onCreateUser">创建新用户</el-button>
        <div class="tips">
          点击创建一个新的登录用户
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      navData: [
        { text: "系统管理", path: '/SysMgr' },
        '用户管理'
      ],
      searchKey: '',
      users: [],
      users_total: 0,
      pageIndex: 1
    }
  },
  computed: {
    userConfig() {
      return {
        cols: [
          { prop: 'EB_USER_ID', label: '用户ID' },
          { prop: 'EB_LOGIN_CODE', label: '登录账号' },
          { prop: 'EB_USER_DESC', label: '用户名' },
          { prop: 'EB_STATE', format: 'state', width: 50 },
          { prop: 'EB_LASTMODIFY_DATETIME', label: '修改时间', width: 170 },
          { format: 'action', width: 70 }
        ],
        pageSize: 15,
      }
    },
    StateObj() {
      return {
        Normal: "正常",
        Disable: "禁用",
        Lock: "锁定"
      }
    }
  },
  methods: {
    onSelectChange(selection) {

    },
    onEditUser(user_id) {
      this.$router.push({
        //path: '/UserMgr/detail',
        name: 'UserMgrDetail',
        params: {
          user_id: user_id
        }
      })
    },
    onDeleteUser(user_id) {
      let me = this
      this.Bus.msg_confirm('确定要删除该用户吗?', _ => {
        this.Bus.ActionFunc('UserMgr-DeleteDetail', {
          user_id: user_id
        }, data => {
          me.RefreshUser()
        })
      })
    },
    onCreateUser() {
      this.$router.push({
        name: 'UserMgrDetail',
        params: {
          create: true
        }
      })
    },
    RefreshUser() {
      let me = this
      this.Bus.ActionFunc('UserMgr-GetList', {
        pageIndex: me.pageIndex,
        pageSize: me.userConfig.pageSize
      }, data => {
        me.$set(this, 'users', data.list)
        me.$set(this, 'users_total', data.total)
      })
    },
    onRefresh() {
      this.RefreshUser()
    }
  },
  mounted() {
    this.RefreshUser()
  }
}
</script>
<style lang="scss">
.usermgr-index-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    padding: 0px 20px 10px 0px !important;

    .user-search {
      margin-bottom: 10px;
    }
    > .user-list {
      .el-card__body {
        padding: 0;
        height: 100%;
      }
      .std-table {
        min-height: 400px;
      }
    }
  }
  .tips {
    font-size: 13px;
    padding: 10px 0;
    color: #626161;
  }
}
</style>